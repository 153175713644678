<template>
    <base-summary-container>
        <div class="summary-section">
            <strong>POLICY NUMBER</strong>: C2G6283{{ policy.quote.id }}
        </div>
        <div class="summary-section">
            <strong>POLICY TYPE</strong>: {{ policyType }}
        </div>
        <div class="summary-section">
            <strong>TRAVEL AREA</strong>: {{ $c2g.area[policy.quote.travel_zone].toUpperCase() }}
        </div>
        <div class="summary-section">
            <strong>DATES</strong>: {{ startDate }} to {{ endDate }}
        </div>
        <div class="summary-section">
            <strong>RELATIONSHIP</strong>: {{ $c2g.relationship[policy.quote.relationship].toUpperCase() }}
        </div>
        <div class="summary-section">
            <strong>AGES</strong>: {{ ages.join(", ") }}
        </div>
        <div class="summary-section" v-if="+policy.quote.trip_cost">
            <strong>CHOSEN CANCELLATION COVER (Per Person)</strong>: &pound;{{ $helper.thousandSeparator(+policy.quote.increased_cxl) }}
        </div>
        <div class="summary-section" key="s10">
            <b-icon icon="check-circle" size="is-small" type="is-primary"/> Level {{ policy.endorsements.activity_pack }}
        </div>
        <div class="summary-section" v-if="Object.entries(endorsements).length !== 0">
            <strong>ENDORSEMENTS</strong>
            <div v-for="e in endorsements" :key="'e' + e.id">
                <b-icon icon="check-circle" size="is-small" type="is-primary"/> {{ e }}
            </div>
        </div>

        <div class="award-container">
            <img src="/img/defaqto.png" alt="defaqto expert rated logo" />
            <trustpilot-widget />
        </div>

    </base-summary-container>
</template>

<script>
    import BaseSummaryContainer from "../../common/BaseSummaryContainer";
    import TrustpilotWidget from '../../common/trustpilot/TrustpilotWidget';
    import { policyUsefulFunctions } from "../../../mixins/policy-useful-functions";
    import { mapGetters } from 'vuex';

    export default {
        name: "QuoteSummary",
        data() {
            return {
                endorsements: [],
                policyType: ''
            }
        },
        computed: {
            ...mapGetters({
                policy: 'policy',
            })
        },
        mixins: [policyUsefulFunctions],
        components: {
            BaseSummaryContainer,
            TrustpilotWidget
        },
        created() {
            this.policyType = this.$c2g.policyType[this.policy.quote.type].toUpperCase();

            this.$c2g.getEndorsements(this.policy.quote.type).then((response) => {
                Object.entries(response.data).forEach((v) => {
                    const dbName = v[0];
                    const fullName = v[1][0];
                    if(this.policy.endorsements[dbName] === 'Yes') {
                        this.endorsements.push(fullName);
                    }
                });
            });
        }
    }
</script>

<style lang="scss" scoped>
    .summary-section {
        padding: 3px 0 3px 0;
        border-bottom: 3px solid $c2g_orange;
    }
    .covidimg {
        margin-top: 10px;
        border-radius: 5px;
    }

    .award-container {
        margin-top: 5px;
        padding: 5px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-evenly;
    }
</style>