<template>
    <div>
        <quote-breadcrumbs :step="3"/>
        <div id="container" class="columns" v-if="policy.quote.product && policy.details.address1">
            <transition appear enter-active-class="animated fadeInUp" leave-active-class="animated fadeOut">
                <policy-information :content="content"/>
            </transition>
            <transition appear enter-active-class="animated fadeInUp" leave-active-class="animated fadeOut">
                <quote-summary/>
            </transition>
        </div>
        <div id="container" v-else-if="!policy.quote.product">
            <div class="box text-center">
                Product needs to be selected on the previous page.
            </div>
        </div>
        <div id="container" v-else>
            <div class="box text-center">
                Address needs to be entered on the previous page.
            </div>
        </div>
    </div>
</template>

<script>
    import QuoteBreadcrumbs from "../components/quote-journey/QuoteBreadcrumbs";
    import PolicyInformation from "../components/quote-journey/purchase-policy/PolicyInformation";
    import QuoteSummary from "../components/quote-journey/purchase-policy/QuoteSummary";
    import { policyAPI } from "../mixins/policy-api";
    import { mapActions, mapGetters } from "vuex";
    import { contentAPI } from "@/mixins/content-api";
    import { page } from "@/mixins/page";
    import { maintenance } from "@/mixins/maintenance";

    export default {
        name: "PurchasePolicy",
        methods: {
            ...mapActions({
                setPolicy: 'setPolicy',
            })
        },
        computed: {
            ...mapGetters({
                policy: 'policy',
            })
        },
        mixins: [ policyAPI, contentAPI, page, maintenance ],
        components: {
            QuoteBreadcrumbs,
            PolicyInformation,
            QuoteSummary
        },
        created() {
            this.maintenanceCheck();
        }
    }
</script>

<style scoped>

</style>