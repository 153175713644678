<template>
    <div class="columns is-multiline">
        <div class="column is-full" v-for="(v, k) in options" :key="k.id" :class="[{selected: val.includes(k)}]">
            <div class="selector hvr-grow-shadow" @click="select(k)">
                <i class="fas" :class="icons[k]" v-if="icons !== null" />
                {{ v }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BoxCheckSelector",
        props: {
            value: { required: true },
            options: { type: Object, required: true },
            colSize: { type: String, default: 'is-half', required: false},
            icons: {type: Object, default: null, required: false}
        },
        model: {
            prop: 'value',
            event: 'selected'
        },
        methods: {
            select(v) {
                if (this.val.includes(v) && this.val.length !== 0) {
                     const newVal = this.val.filter((value) => value !== v);
                     this.val = newVal;
                } else {
                    this.val.push(v);
                }
            }
        },
        computed: {
            val: {
                get() {
                    return this.value;
                },
                set(v) {
                    this.$emit('selected', v);
                }
            },
        },
        mounted() {
          //  console.log(this.options);
        }
    }
</script>

<style lang="scss" scoped>
    // Selector box
    .selector {
        font-size: 14px;
        border: 1px solid gray;
        color: gray;
        padding: 6px 0 4px 5px;
        border-radius: 5px;
        cursor: pointer;
        width: 100%;
        background-color: white;
        box-shadow: 0 2px 5px rgba(black, 0.1);

        i {
            font-size: 16px;
            padding-right: 2px;
        }
    }
    // Select box is selected
    .selected {
        color: white;
        .selector {
            border: 1px solid $c2g_orange;
            color: white;
            background-color: $c2g_orange;
        }
    }
    .column {
        padding-top: 4px;
        padding-bottom: 2px;
    }
</style>