<template>
    <div>
        <!-- Passenger Amount -->
        <div class="columns" v-show="paxAmountChoices.length > 1">
            <div class="input-label title is-6 column">
                <div>How Many Passengers?</div>
            </div>
            <div class="column is-two-fifths">
                <b-select placeholder="Select the amount of passengers" expanded v-model="quoteData.paxAmount">
                    <option v-for="n in paxAmountChoices" :key="n" :value="n">{{ n }}</option>
                </b-select>
            </div>
            <br>
        </div>

        <!-- DOB -->
        <!--<div class="dob-section columns is-multiline">-->
      <div :v-if="paxAmountChoices.length > 1">
        <span :v-if="quoteData.errors.passengers[n-1]" :id="'pax-error-'+(n-1)" class="input-error">{{ quoteData.errors.passengers[n-1] }}</span>
        <div class="columns" v-for="n in quoteData.paxAmount" :key="'p'+n">
            <div class="column is-6">
                <strong>Passenger {{ n }} date of birth:</strong>
            </div>

            <div class="column is-two-fifths">
                <date-of-birth-selector :id="n" v-model="quoteData.dobs[n-1]"/>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
    import DateOfBirthSelector from "../../common/form/DateOfBirthSelector";

    export default {
        name: "QuotePassengers",
        data() {
            return {
                paxAmountChoices: [1],
                dateValid: [ false, false, false, false, false, false, false, false ]
            }
        },
        props: ['quoteData'],
        methods: {
            paxDate(index) {
                return { 'is-valid': this.dateValid[index] };
            },
        },
        watch: {
            'quoteData.relationship'(rel) {
                // Choose correct pax settings depending on relationship type
                switch (rel) {
                    case 'individual':
                        this.paxAmountChoices = [1];
                        this.quoteData.paxAmount = 1;
                        break;
                    case 'couple':
                        this.paxAmountChoices = [2];
                        this.quoteData.paxAmount = 2;
                        break;
                    case 'group':
                        this.paxAmountChoices = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
                        this.quoteData.paxAmount = 2;
                        break;
                    case 'oneparent':
                        this.paxAmountChoices = [2, 3, 4, 5, 6];
                        this.quoteData.paxAmount = 2;
                        break;
                    case 'family':
                        this.paxAmountChoices = [3, 4, 5, 6];
                        this.quoteData.paxAmount = 3;
                        break;
                    case 'extfamily':
                        this.paxAmountChoices = [3, 4, 5, 6];
                        this.quoteData.paxAmount = 3;
                        break;
                    default:
                        this.paxAmountChoices = [];
                        this.quoteData.paxAmount = 0;
                }
                // Clear passengers above the choice limit
                for(let i = 0; i < 14; i++) {
                    if(i >= this.quoteData.paxAmount) {
                        this.quoteData.dobs.splice(i, 1);
                        this.quoteData.ages.splice(i, 1);
                    }
                }
            },
            'quoteData.dobs'() {
                const passengers = [];
                let status = 'L';
                this.quoteData.dobs.forEach((dob, index) => {
                    let originalDOB = dob;
                    // Validate date
                    this.$set(this.dateValid, index, !!dob);
                    // Reformat DOB
                    const dobString = this.$moment(dob, 'DD/MM/YYYY').format('YYYY-MM-DD');
                    dob = this.$moment(dob, 'DD/MM/YYYY');
                    // Calculate age
                    const ageWorkout = this.quoteData.policyType === 'AT' ? this.$moment(this.quoteData.dates[0]).diff(dob, 'years') : this.$moment().diff(dob, 'years');

                    if (this.quoteData.relationship !== 'group' || (this.quoteData.relationship === 'group' && ageWorkout >= 18)) {
                        // Set ages
                        this.$set(this.quoteData.ages, index, ageWorkout);
                        // Push a passenger object
                        passengers.push({ paxid: '', title: '', forename: '', surname: '', age: ageWorkout, dob: dobString, status: status });
                        status = 'A';
                        this.quoteData.errors.passengers[index] = '';
                    } else if (this.quoteData.relationship === 'group' && ageWorkout < 18 && originalDOB.length === 10) {
                        this.quoteData.errors.passengers[index] = 'Please enter a valid DOB. Anyone named on a Group Policy must be aged 18 and over. Please contact us if you need any further assistance.';
                    }

                });
                this.quoteData.passengers = passengers;
            }
        },
        components: {
            DateOfBirthSelector
        }
    }
</script>

<style lang="scss" scoped>
    .field {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .dob-section {
        padding: 0;
    }
    // Date valid signifier
    /deep/.is-valid {
        input {
            background-color: $c2g_orange;
            border-color: $c2g_orange;
            color: white;
        }
        .icon {
            color: white !important;
        }
    }
    .column {
        padding-bottom: 0;
    }
</style>