<template>
    <div class="column is-9">
        <div class="box b-shdw-3">

            <div class="has-text-centered">
                <h4 class="title">Step 3: Your Details & Important Info</h4>
                <p>Please enter your personal details and read the important information below.</p>
            </div>
            <hr>

            <!-- Form -->
            <form @submit.prevent="submit">

                <h6 class="title is-6">Passenger Information</h6>
                <!-- Passengers -->
                <div class="passengers columns" v-for="n in policy.passengers.length" :key="n">

                    <b-field label="Title" label-position="on-border" expanded class="column title is-2">
                        <b-select v-model="policy.passengers[n-1].title" expanded required>
                            <option v-for="t in titles" :key="t.id" :value="t">{{t}}</option>
                        </b-select>
                    </b-field>

                    <b-field label="Forename" label-position="on-border" expanded class="column forename">
                        <b-input v-model="policy.passengers[n-1].forename" required/>
                    </b-field>

                    <b-field label="Surname" label-position="on-border" expanded class="column surname">
                        <b-input v-model="policy.passengers[n-1].surname" required/>
                    </b-field>

                    <b-field class="column">
                        <b-input :value="'Age ' + policy.passengers[n-1].age" disabled/>
                    </b-field>
                </div>

                <h6 class="title is-6">Contact Information</h6>

                <!-- Contact -->
                <b-field label="Main contact telephone number" label-position="on-border">
                    <b-input v-model="policy.details.telephone" required/>
                </b-field>

                <b-field label="Alternative contact number (optional)" label-position="on-border">
                    <b-input v-model="policy.details.mobile"/>
                </b-field>

                <b-field label="Email" label-position="on-border">
                    <b-input v-model="policy.details.email" required/>
                </b-field>

                <!-- Address section -->
                <h6 class="title is-6">Address Information</h6>
                <div class="resident-warning field">
                        <div>This policy is only available to residents of the UK or Channel Islands</div>
                        <div><b-button style="float: right; color: #494949;" :type="'is-ghost'" size="is-small" @click="showInfo = !showInfo"><i class="fas fa-question-circle hvr-icon"/></b-button></div>
                        <div><a href="/non-uk-residents" target="_blank">(Non-UK Residents / Already Travelled)</a></div>
                </div>
                <transition appear enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster">
                    <div v-if="showInfo" class="box">
                        <p>
                            <strong>For your cover to be valid, you,</strong> and anyone you would like named on your policy must:
                            <ul class="resident-warning-list">
                                <li>Be a resident of the UK or Channel Islands</li>
                                <li>Have your trip starting and returning to the UK or Channel Islands</li>
                                <li>Have a permanent residential address in the UK or Channel Islands</li>
                                <li>Have unrestricted right of entry to the UK or Channel Islands</li>
                            </ul>
                        </p>
                    </div>
                </transition>
                <address-selector/>

                <!-- Important information -->
                <important-information v-model="confirmation"/>

                <!-- Error messsage -->
                <article class="message is-danger" v-show="error">
                    <div class="message-body">
                        Please agree to the terms <strong>above</strong> before continuing
                    </div>
                </article>

                <!-- Confirmation button -->
                <button class="button is-primary is-medium is-fullwidth hvr-icon-forward b-shdw-1" :class="{'is-loading': loading}">
                    Confirm <i class="fa fa-chevron-circle-right hvr-icon"/>
                </button>
            </form>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import ImportantInformation from "./ImportantInformation";
    import AddressSelector from "./AddressSelector";

    export default {
        name: "DetailsForm",
        data() {
            return {
                showInfo: false,
                titles: ['Mrs', 'Mr', 'Miss', 'Ms', 'Dr', 'Rev', 'Prof', 'Father', 'Sir', 'Lady', 'Dame', 'Judge', 'Lord', 'Rt.Hon.', 'Master'],
                confirmation: false, // Store if important information has been ticked
                error: false, // Important information error
                loading: false // Next stage is loading
            }
        },
        props: {
            saveFn: {
                type: Function,
                required: true
            }
        },
        methods: {
            // Submit form
            submit() {
                // Display error if confirmation wasn't ticked
                if(!this.confirmation) {
                    this.error = true;
                } else {
                    this.saveFn();
                    this.loading = true;
                }
            }
        },
        watch: {
            // Turn error off if confirmation has been set to true
            confirmation(v) {
                if(v) {
                    this.error = false;
                }
            }
        },
        computed: {
            ...mapGetters({
                policy: 'policy'
            })
        },
        components: {
            ImportantInformation,
            AddressSelector
        }
    }
</script>

<style lang="scss" scoped>
    h4 {
        padding-bottom: 7px;
        border-bottom: $c2g_orange 4px solid;
        margin-bottom: 5px !important;
        font-size: 28px;
    }
    h6 {
        margin-bottom: 17px !important;
        border-bottom: 1px solid $c2g_orange;
        padding-bottom: 5px;
        margin-top: 20px !important;
    }
    .passengers {
        margin-top: 5px;
        .column {
            margin: 5px 0 0 0;
            padding-right: 5px;
            padding-top: 10px;
            padding-bottom: 5px;
        }
        .title {
            padding-right: 0px;
        }
        .forename {
            padding-right: 0px;
        }
    }
    .resident-warning {
        padding-bottom: 5px;
        display: flex; 
        align-items: center;
        font-size: 0.9em;

        div {
            margin-right: 10px;
        }
    }

    .resident-warning-list {
        list-style: disc; 
        margin-left: 10px;

        li {
            margin-left: 20px;
        }
    }
    
    #page-error {
        color: red;
        text-align: center;
    }
</style>