<template>
    <section>
        <div class="columns">
            <div class="input-label title is-6 column">
                <div>Additional Cover Options</div>
                <b-button style="float: right; color: #494949;" :type="'is-ghost'" size="is-small" @click="showInfo = !showInfo" class="hvr-icon-bob is-hidden-desktop"><i class="fas fa-question-circle hvr-icon"/></b-button>
            </div>

            <div class="column is-two-fifths">
                <the-box-check-selector :options="endorsementChoices" :icons="icons" v-model="endorsements" />
            </div>

            <div class="column is-hidden-touch">
                <b-button style="float: right; color: #494949;" :type="'is-ghost'" size="is-small" @click="showInfo = !showInfo" class="hvr-icon-bob"><i class="fas fa-question-circle hvr-icon"/></b-button>
            </div>

        </div>
        <transition appear enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster">
            <div v-if="showInfo" class="box">
                <p>
                    <strong>Cruise Cover</strong><br>If you are going on a cruise holiday, Cruise Cover extends your main policy to cover you whilst you are on a cruise. No cover will be provided for any aspect of a Cruise trip, unless this option is selected, and the appropriate premium paid.
                    <br><br>
                    Cruises are defined as a trip on an ocean, sea or river going vessel which is used for both accommodation and transportation. A ferry crossing does not constitute a cruise.<br>Cruise Cover provides the following benefits:
                    <br><br>
                    <ul>
                        <li>Cover for Missed Port Departure up to £1,500</li>
                        <li>Skipped Port Benefit up to £1,000</li>
                        <li>Cabin Confinement cover up to £750</li>
                        <li>Cover for Cruise Attire up to £1,000</li>
                        <li>Cover for Delayed Cruise Attire up to £250</li>
                        <li>Cover for Shore Excursions up to £1,000</li>
                        <li>Cover for Cruise Interruption up to £1,500</li>
                        <li>Low / High Water Cover up to £2,000</li>
                        <li>Denied Boarding (Additional Expenses) up to £250</li>
                    </ul>
                    <br>
                    For full details, please refer to the <a href="/policy-wordings-mul" target="_blank">Policy Wording.</a>
                </p>
                <br>
                <p>
                    <strong>Winter Sports</strong><br>
                    If you are going on a winter sports holiday and need cover for skiing or snowboarding, make sure you add winter sports cover. Remember it's important to check the policy covers the activities you are participating in.<br>
                    Winter sports cover provides the following benefits:
                    <br><br>
                    <ul>
                        <li>Lost, stolen or damaged winter sports equipment up to £1,000</li>
                        <li>Replacement equipment hire if it is lost, stolen, damaged or delayed up to £500</li>
                        <li>Winter sports pack up to £500</li>
                        <li>Piste closure up to £500</li>
                    </ul>
                    <br>
                    For full details, please refer to the <a href="/policy-wordings-mul" target="_blank">Policy Wording.</a>
                </p>
                <br>
                <p>
                    <strong>Golf</strong><br>Golf cover provides protection for your golf equipment, if it is lost, stolen or damaged. Cover also available if you are unable to play due to adverse weather conditions. You are covered if your golf equipment is delayed by 12 hours. Please see the Policy Wording for full details of cover. Add golf cover by selecting this option and paying the additional premium.
                </p>
                <br>
                <p>
                    <strong>45 Day Extension</strong><br>This option extends the duration of your annual trip policy to 45 days per trip.
                </p>
                <br>
                <p>
                    <strong>60 Day Extension</strong><br>This option extends the duration of your annual trip policy to 60 days per trip
                </p>
                <br>
                <p>
                    <strong>Business Travel (Manual Work)</strong>
                    <ul style="list-style-type: disc; margin: 10px 0px 0px 20px;">
                        <li>
                            You are covered to do non-manual work during your leisure, holiday or business trip under this policy and it can be extended to cover manual work if you have paid an appropriate additional premium and it is shown on your policy schedule.
                        </li>
                        <br>
                        <li>
                            Business Cover will also protect your business equipment if it is lost, stolen, damaged or destroyed. If you are unable to fulfil your business trip, due to illness, we will cover the costs for a replacement employee to take over. Add business cover by selecting this option and paying the additional premium.
                        </li>
                    </ul>
                </p>
                <br>
                <p><strong>Excess Waiver</strong><br>If you select Excess waiver, the excess will be reduced to Nil in the event of a claim. Note: Any excess imposed by Millstream Medical Screening for covering any declared pre-existing medical conditions will still apply.</p>
                <br>
                <p><strong>Double Excess</strong><br>By selecting this option, your premium will be reduced but should you make a claim the excess you will be required to pay will be double the standard excess.</p>
            </div>
        </transition>
    </section>
</template>

<script>
    import {mapGetters} from "vuex";
    import TheBoxCheckSelector from "../../common/form/BoxCheckSelector";

    export default {
        name: "QuoteEndorsements",
        data() {
            return {
                showInfo: false,
                endorsementChoices: {},
                endorsements: [],
                icons: {}
            }
        },
        components: {
            TheBoxCheckSelector
        },
        props: {
            value: { type: Object },
            quoteData: { type: Object, required: true }
        },
        methods: {
            // Query API for endorsement list
            getEndorsementList(type="") {
                this.$c2g.getEndorsements(type).then((response) => {
                    const result = response.data;
                    let icons = {};
                    let endos = {};

                    for (let key in result) {
                        endos[key] = result[key][0];
                        icons[key] = result[key][2];
                    }

                    this.endorsementChoices = endos;
                    this.icons = icons;
                });
            }
        },
        watch: {
            // Get endorsements from API
            'quoteData.policyType'(newType) {
                this.endorsements = []; // Clear selected endorsements on policy type switch
                this.getEndorsementList(newType);
            },
            // Set the endorsements to return back
            endorsements() {
                let returnEndorsements = {};
                this.endorsements.forEach((end) => {
                    returnEndorsements[end] = 'Yes';
                });
                this.$emit('input', returnEndorsements);
            }
        },
        computed: {
            ...mapGetters({
                policy: 'policy'
            })
        },
        created() {
            this.getEndorsementList(this.quoteData.policyType);
        },
        // If the quote form already has endorsement data, populate this tick boxes on load
        mounted() {
            // If there is a policy
            if(this.policy) {
                for (const [key, value] of Object.entries(this.policy.endorsements)) {
                    if(value === 'Yes') {
                        this.$set(this.quoteData.endorsements, key, value);
                        this.endorsements.push(key);
                    }
                }
            // If there isn't a policy
            } else {
                if(this.$route.query.end === 'WS') {
                    this.$set(this.quoteData.endorsements, 'winter_sports', 'Yes');
                    this.endorsements.push('winter_sports');
                }
            }

        }
    }
</script>

<style lang="scss" scoped>
    .is-half {
        padding: 4px 0 4px 0;
    }
    .input-label {
        margin-top: 10px;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    ul {
        margin-left: 25px;
        li {
            list-style: disc;
        }
    }    
    /deep/ {
        .endorsements-container {
            background-color: white;
            overflow: hidden;
            padding: 0;
            margin-top: 10px;
            .columns {
                overflow: hidden;
                padding: 10px 0 10px 10px;
            }
        }
        .media-content {
            overflow: hidden;
        }
    }
</style>