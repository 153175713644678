<template>
    <div class="column">
        <div class="box b-shdw-3">

            <div id="title-section">
                <h4 class="title">Step 2: Select Your Policy Cover Level</h4>
                <!--<p><strong>Your Quote Reference Number is C2G6283{{ reqPolicy.quote.id }}</strong></p>
                <p><strong class="text-c2g-orange" v-if="reqPolicy.policy.promo_code">All premiums quoted include your discount.</strong></p>
                <p>Choose from our range of policy covers to suit your needs and budget.</p>
                <p>All premiums quoted include Insurance Premium Tax.</p>-->
            </div>

            <div class="covid-message">
                <picture>
                    <source srcset="../../../assets/images/icons/heart_icon.webp" type="image/webp"/>
                    <source srcset="../../../assets/images/icons/heart_icon.png" type="image/png"/>
                    <img src="../../../assets/images/icons/heart_icon.webp" alt="Heart Icon" loading="lazy" width="50"/>
                </picture>
                <p>
                    This insurance contains restrictions regarding pre-existing medical conditions which have not been
                    declared and accepted by our medical screening service. We have more information on how to cover
                    pre-existing medical conditions on the next page.
                </p>
            </div>

            <!-- Product titles -->
            <div class="columns">
                <div class="column">
                    <div class="level">
                        <router-link class="button level-item is-info hvr-icon-back b-shdw-1 is-small" :to="{name:'getQuote'}">
                            <i class="fa fa-angle-left hvr-icon"/> Back to Questions
                        </router-link>
                    </div>
                </div>
                <div class="column has-text-centered title-products" v-for="product in productsOffered" :key="product.id">
                    <strong>{{ $helper.stringCapFirst(productCoverName(product)) }}</strong>
                    <br>
                    <img v-if="productCoverName(product) === 'platinum' || productCoverName(product) === 'gold'" src="/img/defaqto-onlystars.png" alt="Defaqto expert rated logo" />
                </div>
            </div>

            <!-- Single trip -->
            <div class="columns">
                
                <template v-if="reqPolicy.quote.type === 'ST'">
                    <div class="column title-section-first has-text-centered" style="display: flex; flex-direction: column; justify-content: center; align-items: center; flex-wrap: nowrap;" oncontextmenu="return false;">
                        <picture>
                            <source srcset="../../../assets/images/icons/st_icon_blue.webp" type="image/webp"/>
                            <source srcset="../../../assets/images/icons/st_icon_blue.png" type="image/png"/>
                            <img src="../../../assets/images/icons/st_icon_blue.webp" alt="Blue Suitcase" width="60"/>
                        </picture>
                        Single Trip
                    </div>
                </template>
                <template v-else-if="reqPolicy.quote.type === 'AT'">
                    <div class="column title-section has-text-centered" style="display: flex; flex-direction: column; justify-content: center; align-items: center; flex-wrap: nowrap;" oncontextmenu="return false;">
                        <picture>
                            <source srcset="../../../assets/images/icons/amt_icon_orange.webp" type="image/webp"/>
                            <source srcset="../../../assets/images/icons/amt_icon_orange.png" type="image/png"/>
                            <img src="../../../assets/images/icons/amt_icon_orange.webp" alt="Two Orange Suitcases" width="60"/>
                        </picture>
                        Annual Multi-Trip
                    </div>
                </template>
                <template v-else-if="reqPolicy.quote.type === 'LS'">
                    <div class="column title-section has-text-centered" style="display: flex; flex-direction: column; justify-content: center; align-items: center; flex-wrap: nowrap;" oncontextmenu="return false;">
                        <picture>
                            <source srcset="../../../assets/images/icons/ls_icon_orange.webp" type="image/webp"/>
                            <source srcset="../../../assets/images/icons/ls_icon_orange.png" type="image/png"/>
                            <img src="../../../assets/images/icons/ls_icon_orange.webp" alt="Orange back pack" width="60"/>
                        </picture>
                        Longstay
                    </div>
                </template>

                <div class="column product-container" v-for="product in productsOffered" :key="product.id">
                    <div class="mobile-product-elements">{{ $helper.stringCapFirst(productCoverName(product)) }}</div>
                    <div class="title is-4">
                        &pound;{{ Number(reqPolicy.quote[`${product}_gross`]).toFixed(2) }} <span class="small">INC. IPT **</span>
                    </div>
                    <b-button type="is-primary" @click="select(reqPolicy.quote.id, product)" class="hvr-icon-forward b-shdw-1" :class="{'is-loading': isLoading, 'button-first': reqPolicy.quote.type === 'ST'}" v-if="reqPolicy.quote[`${product}_gross`] > 0">
                        Select <i class="fa fa-angle-right hvr-icon"/>
                    </b-button>
                    <br /><br />
                    Policy Documents:<br />
                    <a :href="$c2g.getPolicyWordings(product.toUpperCase(), reqPolicy.endorsements.winter_sports == 'Yes', reqPolicy.endorsements.cruise == 'Yes', 13, reqPolicy.quote.type)" class="is-info is-small" target="_blank">Policy Wording</a>,
                    <a :href="$c2g.getIPID(product, reqPolicy.endorsements.winter_sports == 'Yes', reqPolicy.quote.type)" class="is-info is-small" target="_blank">IPID</a>
                    <!-- <div class="mobile-product-elements ipid-mobile-link"><a :href="$c2g.getIPID(product)" target="_blank">View IPID</a></div> -->
                </div>
            </div>

            <div class="columns upsell-message is-hidden-mobile" v-if="reqPolicy.quote.relationship != 'group' && reqPolicy.quote.type === 'ST' && upsellPolicy && Number(reqPolicy.quote[`gold_gross`]) < Number(upsellPolicy.quote[`gold_gross`])">
                <div class="column is-3"><div class="blue-cnt"/><div class="orange-cnt"/></div>
                <div class="column"><p style="margin-top: 6px;"><i class="fas fa-angle-down"></i> Popular choice - Upgrade to Annual Multi-Trip to be covered for multiple trips <i class="fas fa-angle-down"></i></p></div>
            </div>

            <!-- Annual Trip -->
            <div class="columns" v-if="upsellPolicy && reqPolicy.quote.relationship != 'group' && reqPolicy.quote.type === 'ST' && Number(reqPolicy.quote[`gold_gross`]) < Number(upsellPolicy.quote[`gold_gross`])" >
                <div class="column title-section has-text-centered" style="display: flex; flex-direction: column; justify-content: center; align-items: center; flex-wrap: nowrap;">
                    <picture>
                        <source srcset="../../../assets/images/icons/amt_icon_orange.webp" type="image/webp"/>
                        <source srcset="../../../assets/images/icons/amt_icon_orange.png" type="image/png"/>
                        <img src="../../../assets/images/icons/amt_icon_orange.webp" alt="Two Orange Suitcases" width="60"/>
                    </picture>
                    Annual Multi-Trip
                </div>

                <div class="column product-container" v-for="product in productsOffered" :key="product.id">
                    <div class="mobile-product-elements">{{ $helper.stringCapFirst(productCoverName(product)) }}</div>
                    <div class="title is-4">
                        &pound;{{ Number(upsellPolicy.quote[`${product}_gross`]).toFixed(2) }} <span class="small">INC. IPT **</span>
                    </div>
                    <b-button type="is-primary" :class="{'is-loading': isLoading}" @click="select(upsellPolicy.quote.id, product)" class="hvr-icon-forward b-shdw-1">
                        Select <i class="fa fa-angle-right hvr-icon"/>
                    </b-button>
                    <br /><br />
                    Policy Documents:<br />
                    <a :href="$c2g.getPolicyWordings(product.toUpperCase(), upsellPolicy.endorsements.winter_sports == 'Yes', upsellPolicy.endorsements.cruise == 'Yes', 13, upsellPolicy.quote.type)" class="is-info is-small" target="_blank">Policy Wording</a>,
                    <a :href="$c2g.getIPID(product, upsellPolicy.endorsements.winter_sports == 'Yes', upsellPolicy.quote.type)" class="is-info is-small" target="_blank">IPID</a>
                    <!-- <div class="mobile-product-elements ipid-mobile-link"><a :href="$c2g.getIPID(product)" target="_blank">View IPID</a></div> -->
                </div>
            </div>

            <!-- Policy documentation -->
            <!-- <policy-documents :productsOffered="productsOffered"/> -->

            <!-- Products covers -->
            <product-covers :productsOffered="productsOffered" :productCoverName="productCoverName" :cruise="this.reqPolicy.endorsements.cruise" :ws="this.reqPolicy.endorsements.winter_sports"/>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    //import PolicyDocuments from "./PolicyDocuments";
    import ProductCovers from "./ProductCovers";

    export default {
        name: "ProductSelection",
        data() {
            return {
                productsOffered: [],
                isLoading: false
            }
        },
        props: {
            selectProductFn: { type: Function, required: true }
        },
        computed: {
            ...mapGetters({
                reqPolicy: 'reqPolicy',
                upsellPolicy: 'upsellPolicy'
            })
        },
        components: {
            //PolicyDocuments,
            ProductCovers
        },
        methods: {
            // Select product
            select(id, product) {
                this.selectProductFn(id, product);
                this.isLoading = true;
            },
            productCoverName(p) {
                if(this.reqPolicy.quote.type === 'LS') {
                    return 'longstay';
                } else {
                    return p;
                }
            }
        },
        created() {
            // Retrieve products covered from API
            this.$c2g.getProductsOffered(this.reqPolicy.quote.id).then((res) => {
                this.productsOffered = JSON.parse(res.data).reverse();
            });
        }
    }
</script>

<style lang="scss" scoped>
    @import "src/styles/views/product-selection";

    .small {
        font-size: 8px;
    }

    .covid-message {
        background-color: #f9923167;
        margin: 10px;
        padding: 10px;
        border-radius: 10px;

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1%;

        p {
            flex-basis: 70%;
            flex-grow: 1;
        }
    }
</style>